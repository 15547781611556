<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-back-button color="primary"></ion-back-button>
        </ion-buttons>
        <ion-title>Nuovo User</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
        </ion-toolbar>
      </ion-header>
      <div id="container">
        <form @submit="submit">
          <label>Nome:</label>
          <ion-input id="input" v-model="user.name"></ion-input>
          <label>E-mail:</label>
          <ion-input id="input" v-model="user.email"></ion-input>
          <label>Company:</label>
          <ion-select id="input" placeholder="Seleziona la company di riferimento" v-model="user.company">
            <ion-select-option :value="c.name" v-for="c,i in companies" :key="'account-'+i">{{c.name}}</ion-select-option>
          </ion-select>
          <label>Livello:</label>
            <ion-select placeholder="Seleziona il livello.." v-model="user.role">
              <ion-select-option :value="r" v-for="r,i in ruoli" :key="'role-'+i">{{roles[i]}}</ion-select-option>
            </ion-select>
          <label>Password:</label> <img style="width:25px;height:22px;margin-top:5px;" src="images\showpwd.png" @click="viewpwd=!viewpwd">
          <ion-input id="input" type="password" v-model="user.password" v-if="viewpwd==false"></ion-input> 
          <ion-input id="input" v-model="user.password" v-if="viewpwd==true"></ion-input> 
          <ion-button type="submit">SALVA</ion-button>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import {
    IonBackButton, 
    IonButton,
    IonButtons,
    IonContent, 
    IonHeader,
    IonInput, 
    IonMenuButton, 
    IonPage,
    IonSelect,
    IonSelectOption, 
    IonTitle, 
    IonToolbar,
    toastController,
  } from '@ionic/vue';

  export default {
    name: 'Folder',
    components: {
      IonBackButton,
      IonButton,
      IonButtons,
      IonContent,
      IonHeader,
      IonInput, 
      IonMenuButton,
      IonPage,
      IonSelect,
      IonSelectOption, 
      IonTitle,
      IonToolbar
    },
    data: function(){
      return{
        user:{
          name:'',
          email:'',
          role:0,
          company:'',
          password:'',
        },
        ruoli:[0,1,2,3,4,5,6,7],
        roles:['Cliente','Consulente','Interinale','Stagista','Dipendente','Admin','Company admin','Superadmin'],
        companies:[],
        viewpwd:false,
      }
    },
    methods:{
      submit(ev){
        ev.preventDefault();
        this.axios.post(this.apiUrl + 'save-user', {user:this.user}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.user.name='';
            this.user.email='';
            this.user.role=0;
            this.user.company='';
            const toast = await toastController
            .create({
              color: 'success',
              message: response.data.message,
              duration: 2000
            })
            this.$router.push('/folder/users-list');
            return toast.present();
          }
        })
      },
      getCompaniesList(){
        this.axios.post(this.apiUrl + 'get-companies-list', {}, this.headers)
          .then(async (response) => {
            if(response.data.status_code==200){
              this.companies=response.data.companies            
            }
          })
      },      
    },
    ionViewWillEnter: function(){
      this.getCompaniesList();
    }
  }
</script>
<style scoped>
  #input{
    background-color:#a9bdff48;
    border-radius:5px;
    margin:5px;
    width:90%;
  }
  form{
    padding:10px;
  }
  ion-select{
    background-color:#a9bdff48;
    border-radius:5px;
    margin:5px;
    width:90%;
  }
</style>